import React, { useEffect } from 'react'
import logo from "../assets/logo.png";
import { Link } from 'react-router-dom';
import whatsapp from "../assets/img/whatsapp-logo.png";

const Header = () => {
    const mobileToggle = (e) => {
        const selector = document.querySelector('#navbar');
        selector.classList.toggle('navbar-mobile')
        e.target.classList.toggle('bi-list')
        e.target.classList.toggle('bi-x')
    }

    // useEffect(() => {
    //     const selectorLink = document.querySelectorAll('.nav-link');
    //     const navtoggle = () => {
    //         selectorLink.forEach(navlinks => {
    //             const navSelector = document.querySelector('#navbar');
    //             const toggleIcon = document.querySelector('#toggle-icon');
    //             if (document.getElementsByClassName('bi-x')) {
    //                 toggleIcon.classList.toggle('bi-x')
    //             }
    //             if (document.getElementsByClassName('navbar-mobile')) {
    //                 navSelector.classList.toggle('navbar-mobile');
    //             }
    //         })
    //     }
    //     window.addEventListener('click', navtoggle);
    //     return () => {
    //         window.removeEventListener('click', navtoggle);
    //     }
    // }, [])

    return (
        <header id="header" className="fixed-top">
            <div className="container d-flex align-items-center">

            {/* <h1 className="logo me-auto"><a href="index.html">Arsha</a></h1> */}
            <Link to="/" className="logo me-auto">
                <img src={logo} alt="" className="img-fluid" />
            </Link>

            <nav id="navbar" className="navbar">
                <ul>
                    <li><a className="nav-link scrollto active" href="#services">Features</a></li>
                    <li><a className="nav-link scrollto" href="#cta">Workflow</a></li>
                    <li><a className="nav-link scrollto" href="#api">MizXtractAPI</a></li>
                    {/* <li><a className="nav-link scrollto" href="#plans">Pricing</a></li> */}
                    <li><a className="nav-link scrollto" href='https://app.mizybook.com/login' target='_blank'><button className='btn btn-outline-primary signUp-btn'>Sign In</button></a></li>
                    <li><a className="nav-link scrollto" href='https://app.mizybook.com/signup' target='_blank'><button className='btn btn-outline-primary signUp-btn'>Sign Up</button></a></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle" id="toggle-icon" onClick={(e) => mobileToggle(e)}></i>
            </nav>

            </div>
            <a className="position-relative" href='https://api.whatsapp.com/send/?phone=919986832942&text=Hi'><img className="pulse" src={whatsapp} alt="" /></a>
        </header>
    )
}

export default Header