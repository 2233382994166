import React from 'react'
import Workflow from '../../assets/img/workflow.png';

const WorkFlow = () => {
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div class="section-title">
          <h2>How Does Mizybook Help?</h2>
          {/* <p className='sub-heading'>Successful AI depends on choosing the right problem to solve using AI. 
Quality and availability of data is a critical success factor for AI projects. 
Our unique 4 E model ensures you always stay on track.</p> */}
        </div>
        <div className="row">
          <div className="col-lg-6 text-center text-lg-start">
                <div className="v-progress" data-aos="zoom-in" data-aos-delay="200">
                    <ul>
                        <li className="v-progress-item completed">
                            <h4 className='workflow-heading'>Click or Upload</h4>
                            <p className='workflow-desc'>Just click a photo of your bill using your mobile or upload<br/>its PDF.</p>
                        </li>
                        <li className="v-progress-item completed">
                            <h4 className='workflow-heading'>Automatic Data Capture</h4>
                            <p className='workflow-desc'>Our smart system reads your bill and takes out all the important <br/>details relevant for accounting and tax compliances.</p>
                        </li>
                        <li className="v-progress-item completed">
                            <h4 className='workflow-heading'>Validation</h4>
                            <p className='workflow-desc'>We then match the details with the relevant law and rules and make <br/>sure everything is correct. Details are cross verified internally as well <br/>as with GST and IncomeTax portal to ensure accuracy.</p>
                        </li>
                        <li className="v-progress-item completed">
                            <h4 className='workflow-heading'>Easy Transfer to <br/>ERP/ Tally/ Quickbook/ Excel</h4>
                            <p className='workflow-desc'>Once checked, this detail goes straight into your accounting system. <br/>No mistakes, no hassle!</p>
                        </li>
                    </ul>
                </div>
          </div>
          <div className="col-lg-6 cta-btn-container text-center" data-aos="zoom-in" data-aos-delay="200">
                <img className='img-fluid' src={Workflow} alt='workflow' />
          </div>
        </div>

      </div>
    </section>
  )
}

export default WorkFlow