import React from 'react'

const Features = () => {
  return (
    <section id="services" className="services">
        <div className="container" data-aos="fade-up">

            <div className="section-title">
                <h2>Key Features</h2>
                {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>

            <div className="row">
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                    <div className="icon-box">
                    <div className="icon"><i className="fas fa-file-alt"></i></div>
                    <h4><a href="">Data Input Handling</a></h4>
                    <p>Support for various document formats (PDF, scanned images,  etc.). <br/> Batch processing capabilities for handling multiple invoices simultaneously.</p>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                    <div className="icon-box">
                    <div className="icon"><i className="fas fa-exchange-alt"></i></div>
                    <h4><a href="">Data Extraction</a></h4>
                    <p>Extraction of key invoice data such as invoice number, date, vendor information, and line items. <br/> Ability to capture custom data fields</p>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div className="icon-box">
                    <div className="icon"><i className="fas fa-check"></i></div>
                    <h4><a href="">Data Validation</a></h4>
                    <p>Data validation against predefined rules to ensure accuracy and consistency. <br/> Exception handling for cases where data extraction is ambiguous or inaccurate</p>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="400">
                    <div className="icon-box">
                    <div className="icon"><i className="fas fa-filter"></i></div>
                    <h4><a href="">Line Item Extraction</a></h4>
                    <p>Extraction of line item details including descriptions, quantities, unit prices, and totals. <br/> Support for multi-page invoices with variable line item layouts</p>
                    </div>
                </div>
                
                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                    <div className="icon-box">
                    <div className="icon"><i className="fas fa-medal"></i></div>
                    <h4><a href="">Vendor Recognition</a></h4>
                    <p>Vendor database for recognizing and associating invoices with specific vendors. <br/> Ability to learn and adapt to new vendors over time</p>
                    </div>
                </div>

                <div className="col-xl-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="400">
                    <div className="icon-box">
                    <div className="icon"><i class="fas fa-file-invoice-dollar"></i></div>
                    <h4><a href="">Invoice Matching</a></h4>
                    <p>Matching extracted data with purchase orders or contracts to ensure compliance.<br/><br/>Flagging of discrepancies and exceptions</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
  )
}

export default Features