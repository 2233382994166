import './App.css';
import './assets/css/style.css';
import "bootstrap/dist/css/bootstrap.css";
import "./assets/vendor/bootstrap-icons/bootstrap-icons.css";
import "./assets/vendor/boxicons/css/boxicons.min.css";
import HomePage from './Pages/HomePage';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  useEffect(() => {
    let backtotop = document.querySelector('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      window.addEventListener('scroll', toggleBacktotop)

      return () => {
        window.removeEventListener('scroll', toggleBacktotop);
      }
    }
  },[])
    
  useEffect(() => {
    let selectHeader = document.querySelector('#header')
    console.log(selectHeader);
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 50) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      window.addEventListener('scroll', headerScrolled)

      return () => {
        window.removeEventListener('scroll', headerScrolled)
      }
    }
  }, [])
  
  // useEffect(() => {
  //   window.addEventListener('load', () => {
  //     if (window.location.hash) {
  //       if (document.querySelector(window.location.hash)) {
  //         window.addEventListener('scroll', window.location.hash)
  //       }
  //       return () => {
  //         window.removeEventListener('scroll', window.location.hash);
  //       }
  //     }
  //   });
  // }, [])

  useEffect(() => {
    let navbarlinks = document.querySelectorAll('#navbar .scrollto', true)
    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return
        let section = document.querySelector(navbarlink.hash)
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    window.addEventListener('scroll', navbarlinksActive)
    return () => {
      window.removeEventListener('scroll', navbarlinksActive);
    }
  },[])

  useEffect(() => {
    const AosInit = () => {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
      });
      AOS.refresh();
    }
    if (document.readyState === "complete") {
      AosInit()
    } else {
      window.addEventListener('load', AosInit);
    }
    return () => {
      window.removeEventListener('load', AosInit)
    }
  }, [])

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={ <HomePage/> } />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
