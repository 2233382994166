import React, { useEffect } from 'react'
import Banner from '../Components/HomePage/Banner';
import Clients from '../Components/HomePage/Clients';
import ContactUs from '../Components/HomePage/ContactUs';
import Features from '../Components/HomePage/Features';
import Api from '../Components/HomePage/Api';
import WorkFlow from '../Components/HomePage/WorkFlow';
import Plans from '../Components/HomePage/Plans';
import BusinessEntity from '../Components/HomePage/businessEntity';

const HomePage = () => {

    return (
        <>
            <Banner />
            <main id="main">
                {/* <Clients /> */}
                <Features />
                <WorkFlow />
                <Api />
                {/* <Plans /> */}
                {/* <BusinessEntity /> */}
                <ContactUs />
            </main>
        </>
    )
}

export default HomePage;