import React from 'react'

const Api = () => {
    return (
        <section id="api" class="api">
        <div class="container" data-aos="fade-up">

            <div class="section-title">
            <h2>MizXtract API</h2>
            {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>

            <div class="row">
                <div class="col-xl-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon-box">
                    {/* <div class="icon"><i class="bx bxl-dribbble"></i></div> */}
                        <h4><a href="">Accounting Automation</a></h4>
                        <p>Json response provided for invoice no, invoice date, invoice taxable value, tax (CGST, SGST,IGST), SAC code &, vendor GSTIN. This can be mapped with ERP codes</p>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon-box">
                    {/* <div class="icon"><i class="bx bx-file"></i></div> */}
                        <h4><a href="">GST Compliance</a></h4>
                        <p>Json response is provided for vendor GST status, filing periodicity, classification into RCM/GST/Exempt type, availability in GSTR2B, etc. for use in GST return filing</p>
                    </div>
                </div>

                <div class="col-xl-4 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon-box">
                    {/* <div class="icon"><i class="bx bx-tachometer"></i></div> */}
                        <h4><a href="">TDS Compliance</a></h4>
                        <p>Json response is provided for vendor PAN status, deduction rate and amount for TDS, LDC consumption amount, etc to be used for TDS return filing</p>
                    </div>
                </div>

            </div>

        </div>
    </section>
    )
}

export default Api