import React from 'react'
import Hero from '../../assets/img/hero-img.png';
import Video from '../../assets/Mizybook1.mp4';

const Banner = () => {
    return (
        <section id="hero" className="d-flex align-items-center">

            <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1">
                <h2>Finance Compliance Made Easy - with Mizybook</h2>
                <h1>Simplify your Financial Tasks with Mizybook</h1>
                <div className="d-flex justify-content-center justify-content-lg-start">
                    {/* <a href="#about" className="btn-get-started scrollto">Get Started</a> */}
                    {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
                    <p className='sub-text'>Accounting and finance can be tricky, especially <br/>when dealing with compliance. But why worry? <br/>Mizybook is here to make things easy for you.</p>
                </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2 hero-img">
                    {/* <img src={Hero} className="img-fluid animated" alt="" /> */}
                    <video className="img-fluid animated" autoPlay muted loop style={{borderRadius : "20px"}}p>
                        <source src={Video} type="video/mp4"/>  
                    </video>    
                </div>
            </div>
            </div>

        </section>
    )
}

export default Banner