import React, { useEffect } from 'react'
import LogoFooter from '../assets/footerLogo.png';
import { Link } from 'react-router-dom';

const Footer = () => {

    useEffect(() => {
        let preloader = document.querySelector('#preloader');
        if (preloader) {
            window.addEventListener('load', () => {
                preloader.remove()
            });
        }
    },[])

    return (
    <>
        <footer id="footer">

            {/* <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 footer-contact">
                            <div className="w-100 d-flex align-items-center justify-content-between flex-wrap">
                                <Link to="/">Contact Us</Link>
                                <Link to="/">Imprints</Link>
                                <Link to="/">Privacy Policy</Link>
                                <Link to="/">Cokkie Policy</Link>
                                <Link to="/">Legal Terms</Link>
                            </div>
                            <div className='img-box mt-lg-5 mt-3'> 
                                <img className='w-75' src={LogoFooter} alt='' />
                            </div>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Resources</h4>
                            <ul className='mt-lg-5 mt-3'>
                                <li><Link to="/">Blog</Link></li>
                                <li><Link to="/">Guides and Reports</Link></li>
                                <li><Link to="/">Videos</Link></li>
                                <li><Link to="/">Mizybook Academy</Link></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-6 footer-links">
                            <h4>Company</h4>
                            <ul className='mt-lg-5 mt-3'>
                                <li><Link to="/">About Us</Link></li>
                                <li><Link to="/">Press Releases</Link></li>
                                <li><Link to="/">Careers</Link></li>
                                <li><Link to="/">Subscribe to our Newsletter</Link></li>
                                <li><Link to="/">Partners</Link></li>
                                <li><Link to="/">Mizybook Open</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container footer-bottom clearfix pt-4 text-center">
                {/* <div className="copyright d-flex align-items-center justify-content-between flex-wrap">
                    <h4>Contact Us</h4>
                    <h4>Imprints</h4>
                    <h4>Privacy Policy</h4>
                    <h4>Cokkie Policy</h4>
                    <h4>Legal Terms</h4>
                    </div> */}
                <div class="copyright w-100" style={{float : "none"}}>
                        &copy; Copyright <strong><span>Mizybook @{ new Date().getFullYear()}</span></strong>. All Rights Reserved
                </div>
                {/* <div className="social-links">
                    <Link to="/" className="twitter"><i className="bx bxl-twitter"></i></Link>
                    <Link to="/" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                    <Link to="/" className="google-plus"><i className="bx bxl-whatsapp"></i></Link>
                </div> */}
            </div>
        </footer>
        {/* <div id="preloader"></div> */}
        <Link to="/" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
    </>
  )
}

export default Footer